<template>
    <b-container>
      <center><h2>Documentations</h2></center>
      <b-row> 
        <b-col>
          <div>
            <b-card title="Documentations utiles" sub-title="Ces liens pointent vers les documentations officielle pajemploi" style="text-align:left">
              <b-card-text>
                
              </b-card-text>

                <span v-for="lien in liensUtiles" :key="lien">
                      <b-link :href="lien.link" class="card-link" target="_blank">{{lien.Title}}</b-link><br/>
                </span>
            </b-card>
          </div>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-card title="Correspondance pajkit / pajemploi" sub-title="Vous permet de visualiser la correspondance entre les champs" style="text-align:left">
              <b-card-text>
                
              </b-card-text>
            </b-card>
          </div>
        <br />
        </b-col>
      </b-row>
    </b-container>
</template>

<script>


export default {
  name: 'nouNouDocs',
  components: {

  },
  props: {

  },
  data() {
      return {
        liensUtiles : [
          {"id" : 0, "Title" : "Déclarer les congés", "link" : "https://www.pajemploi.urssaf.fr/pajewebinfo/cms/sites/pajewebinfo/accueil/employeur-dassistante-maternelle/je-remunere-et-je-declare/declarer-les-conges-payes.html#544f4220-3426-4e4c-975d-03d5fa216285"},
          {"id" : 1, "Title" : "Déclarer les jours d'activité", "link" : "https://www.pajemploi.urssaf.fr/pajewebinfo/cms/sites/pajewebinfo/accueil/employeur-dassistante-maternelle/je-remunere-et-je-declare/declarer-les-jours-dactivite.html"},
          {"id" : 2, "Title" : "Les absences", "link" : "https://www.pajemploi.urssaf.fr/pajewebinfo/cms/sites/pajewebinfo/accueil/employeur-dassistante-maternelle/je-recrute-et-jemploie/les-jours-dabsence-et-le-remplac.html#36892449-bd56-4162-9593-9ccd0bb3e2d7"},
          {"id" : 3, "Title" : "", "link" : ""},

        ]
      }
  },
  methods: {
    
  }
}
</script>

<style>
.card-group .card {
    max-width: 10%;
}
</style>