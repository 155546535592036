<template>
  <b-container class="bv-example-row">
  <template>
  <b-row>
    <b-col>
      Calculer le salaire mensualisé et génerer un résumé pour la déclaration pajemploi<br/> 
    </b-col>
  </b-row>
  <b-row>
    <b-col align-self="center">
        <b-card title="Nom de l'enfant" sub-title="Saisir le nom de l'enfant" style="text-align:left">
        <br />
        <b-form-input :id="enfant" :value="enfant" v-model="enfant" type="text" placeholder="Saisir le nom de l'enfant" required></b-form-input>
        </b-card>
        <br />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-card title="Mensualisation" sub-title="Déclarer ici les informations de mensualisation mentionnés dans votre contrat" style="text-align:left">
        <br/>
        <b-container>
          <b-row class="my-1" v-for="value in mensualisation" :key="value.id">
            <b-col sm="5">
              {{value.label}}
            </b-col>
            <b-col sm="4">
              <b-form-input :id="`${value.id}`" :value="value.value" v-model="value.value" required :type="value.type" :formatter="virgulePoint"></b-form-input>
              <b-tooltip :target="`${value.id}`" triggers="hover">
                {{value.tips}}
              </b-tooltip>
            </b-col>
            <b-col sm="3">
              <i :class="value.icon"></i>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <br />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-card title="Tarifs" sub-title="Déclarer ici les tarifs des indemnités mentionnés dans votre contrat" style="text-align:left">
        <br />
        <b-container>
          <b-row class="my-1" v-for="tarif in tarifs" :key="tarif.id">
           <b-col sm="5">
              {{tarif.label}}
            </b-col>
            <b-col sm="4">
              <b-form-input :id="`${tarif.id}`" :value="tarif.value" v-model="tarif.value" :type="tarif.type" required :formatter="virgulePoint"></b-form-input>
              <b-tooltip :target="`${tarif.id}`" triggers="hover">
                {{tarif.tips}}
              </b-tooltip>
            </b-col>
            <b-col sm="3">
              <i :class="tarif.icon"></i>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <br />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-card title="Indemnités" sub-title="Déclarer ici les nombres d'indemnités réels sur le mois" style="text-align:left">
        <br />
        <b-container>
          <b-row class="my-1" v-for="indemnite in indemnites" :key="indemnite.id">
            <!--{{value}} {{index}}-->
            <b-col sm="5">
              <!--<label :for="`${indemnite.label}`"><b>{{indemnite.label}}:</b></label>-->
              {{indemnite.label}}
            </b-col>
            <b-col sm="4">
              <b-form-input :id="`${indemnite.id}`" :value="indemnite.value" v-model="indemnite.value" :type="indemnite.type"  :formatter="virgulePoint" required></b-form-input>
              <b-tooltip :target="`${indemnite.id}`" triggers="hover">
                {{indemnite.tips}}
              </b-tooltip>
            </b-col>
            <b-col sm="3">
              <i :class="indemnite.icon"></i>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <br />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-card title="Déductions" sub-title="Indiquer les déductions de congés et d'heures à comptabiliser sur le mois" style="text-align:left">
        <br />
        <b-container fluid>
          <b-row class="my-1" v-for="deduction in deductions" :key="deduction.id">
            <!--{{value}} {{index}}-->
            <b-col sm="5">
              <!--<label :for="`${deduction.label}`"><b>{{deduction.label}}:</b></label>-->
              {{deduction.label}}
            </b-col>
            <b-col sm="4">
              <b-form-input :id="`${deduction.id}`" :value="deduction.value" v-model="deduction.value" :type="deduction.type" :formatter="virgulePoint" required></b-form-input>
              <b-tooltip :target="`${deduction.id}`" triggers="hover">
                {{deduction.tips}}
              </b-tooltip>
            </b-col>
            <b-col sm="3">
              <i :class="deduction.icon"></i>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <br />
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <center><b-button variant="success" size="lg" v-b-modal.modal-2 v-on:click="mainProgram()">Calculer le salaire</b-button></center>  
      <br />
    </b-col>
  </b-row>
</template>

<b-modal id="modal-2" :title="enfant" ok-only>
    <p><i>Retrouvez ici un r&eacute;sum&eacute; des informations pour la d&eacute;claration pajEmploi ainsi que le calcul détaillé</i></p>
    <b-tabs content-class="mt-3">
      <b-tab title="Déclaration PajEmploi" active>
        <b-table :items="pajKit" striped hover :fields="['Element','Valeur']" thead-class="d-none">
          <template #cell(Valeur)="data">
                  <span style="font-family:Courier New"><b>{{ data.value }}</b></span>
          </template>
        </b-table>
        <br/>
        <a href="#">Rendez-vous dans la rubrique Documentation pour savoir comment déclarer sur pajemploi</a>
      </b-tab>
      <b-tab title="Détails du calcul">
        <b-container>
          <b-row>
            <b-col>
              <b-table :items="resultats" striped hover :fields="['Element','Valeur']" thead-class="d-none">
                <template #cell(Element)="data">
                  <div style="text-align:left">{{ data.value }}</div>
                </template>
                <template #cell(Valeur)="data">
                  <span style="font-family:Courier New"><b>{{ data.value }}</b></span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
    
    
</b-modal>

</b-container>




</template>

<script>

export default {
    name: "nouNouCalc",
    props: {
        msg: String,
    },
    data() {
        return {
            enfant: "",
            result: false,
            resultats: [],
            pajKit: [],
            mensualisation: {
                heures: { "id": "nbheures", "type": "text", "label": "Nombre d'heures", "value": 0, "formatter": "virgulePoint", "icon": "fas fa-clock fa-2x", "tips": "Indiquer ici le nombre d'heures mensualisées inscrit dans le contrat" },
                jours: { "id": "jours", "type": "text", "label": "Nombre de jours", "value": 0, "icon": "fas fa-calendar-alt fa-2x", "tips": "indiquer ici le nombre de jours mensualisés" },
                salaire: { "id": "baseSalaire", "type": "text", "label": "Salaire de base", "value": 0, "icon": "fas fa-euro-sign fa-2x", "tips": "indiquer ici le salaire de base mensualisé" }
            },
            indemnites: {
                nbEntretien: { "id": "nbEntretien", "type": "text", "label": "Nombre d'entretien", "value": 0, "icon": "fas fa-wrench fa-2x", "tips": "Indiquer ici le nombre d'indemnités entretien réel" },
                nbEntretienPartiel: { "id": "nbEntretienPartiel", "type": "text", "label": "Nombre d'entretien Partiel", "value": 0, "icon": "fas fa-wrench fa-2x", "tips": "Indiquer ici le nombre d'indemnités entretien Partiel réel (jours < 6h44 de garde)" },
                nbRepas: { "id": "nbRepas", "type": "text", "label": "Nombre de repas", "value": 0, "icon": "fas fa-utensils fa-2x", "tips": "Indiquer ici le nombre d'indemnités repas réel" },
                nbGouter: { "id": "nbGouter", "type": "text", "label": "Nombre de goûter", "value": 0, "icon": "fas fa-cookie-bite fa-2x", "tips": "Indiquer ici le nombre d'indemnités goûter réel" },
            },
            tarifs: {
                entretien: { "id": "tarifEntretien", "label": "Tarif Entretien", "value": 0, "icon": "fas fa-wrench fa-2x", "tips": "Indiquer ici le coût unitaire de l'indemnité entretien prévu dans le contrat" },
                entretienPartiel: { "id": "tarifEntretienPartiel", "label": "Tarif Entretien Partiel", "value": 0, "icon": "fas fa-wrench fa-2x", "tips": "Indiquer ici le coût unitaire de l'indemnité entretien partiel prévu dans le contrat" },
                repas: { "id": "tarifRepas", "label": "Tarif Repas", "value": 0, "icon": "fas fa-utensils fa-2x", "tips": "Indiquer ici le coût unitaire de l'indemnité repas prévu dans le contrat" },
                gouter: { "id": "tarifGouter", "label": "Tarif Gouter", "value": 0, "icon": "fas fa-cookie-bite fa-2x", "tips": "Indiquer ici le coût unitaire de l'indemnité goûter prévu dans le contrat" },
                horaire: { "id": "tarifHoraire", "label": "Tarif Horaire", "value": 0, "icon": "fas fa-clock  fa-2x", "tips": "Indiquer ici le coût unitaire d'une heure de garde prévue dans le contrat" },
            },
            deductions: {
                heures: { "id": "jourDeduit", "type": "text", "label": "Nombre d'heures à déduire", "value": 0, "icon": "fas fa-user-clock fa-2x", "tips": "Indiquer ici le nombre d'heures à déduire" },
                conges: { "id": "jourConges", "type": "text", "label": "Jours de congés pris", "value": 0, "icon": "fas fa-umbrella-beach fa-2x", "tips": "Indiquer ici le nombre de jours de congés pris" }
            },
            declaration: { 
                           "salaire_mensuel": 0, 
                           "total_des_Indemnites": 0, 
                           "indemnites_entretien": 0, 
                           "indemnites_entretien_partiel": 0, 
                           "total_gouter": 0, 
                           "total_repas": 0, 
                           "montant_heures_deduites": 0, 
                           "jours_conges_pris": 0 
                          },
        };
    },
    watch: {
        enfant(newValue) {
            localStorage.enfant = newValue;
        },
        "mensualisation.heures.value"(newValue) {
            localStorage.heures = Number(newValue);
        },
        "mensualisation.jours.value"(newValue) {
            localStorage.jours = Number(newValue);
        },
        "mensualisation.salaire.value"(newValue) {
            localStorage.salaire = Number(newValue);
        },
        "tarifs.entretien.value"(newValue) {
            localStorage.tarifEntretien = Number(newValue);
        },
        "tarifs.entretienPartiel.value"(newValue) {
            localStorage.tarifEntretienPartiel = Number(newValue);
        },
        "tarifs.gouter.value"(newValue) {
            localStorage.tarifGouter = Number(newValue);
        },
        "tarifs.repas.value"(newValue) {
            localStorage.tarifRepas = Number(newValue);
        },
        "tarifs.horaire.value"(newValue) {
            localStorage.tarifHoraire = Number(newValue);
        }
    },
    methods: {
        /*toggleResult: function () {
            this.result = !this.result || false;
        },*/
        virgulePoint: function (value) {
            return value.replace(",", ".");
        },
        calculate: function () {
            this.resultats = [];
            this.declaration.indemnites_entretien = this.indemnites.nbEntretien.value * this.tarifs.entretien.value
            this.declaration.indemnites_entretien_partiel = this.indemnites.nbEntretienPartiel.value * this.tarifs.entretienPartiel.value
            this.declaration.total_repas = this.indemnites.nbRepas.value * this.tarifs.repas.value
            this.declaration.total_gouter = this.indemnites.nbGouter.value * this.tarifs.gouter.value
            this.declaration.montant_heures_deduites = this.deductions.heures.value * this.tarifs.horaire.value
            this.declaration.jours_conges_pris = this.deductions.conges.value
            this.declaration.total_des_Indemnites = this.declaration.indemnites_entretien +
                this.declaration.indemnites_entretien_partiel +
                this.declaration.total_gouter +
                this.declaration.total_repas
            this.declaration.salaire_mensuel = this.mensualisation.salaire.value -
                this.declaration.montant_heures_deduites +
                this.declaration.total_des_Indemnites
            this.declaration.base_salaire = this.mensualisation.salaire.value
        },

        generateResult: function () {
            this.resultats.push({ "Element": "Heures Mensualisées", "Valeur": this.mensualisation.heures.value, "paj": "oui", _rowVariant: "" })
            this.resultats.push({ "Element": "Jours Mensualisés", "Valeur": this.mensualisation.jours.value, "paj": "oui", _rowVariant: "" })
            this.resultats.push({ "Element": "Salaire Base", "Valeur": parseFloat(this.mensualisation.salaire.value).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Heures Déduites", "Valeur": parseFloat(this.declaration.montant_heures_deduites).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Salaire Net hors indemnités", "Valeur": parseFloat(this.mensualisation.salaire.value - this.declaration.montant_heures_deduites).toFixed(2)+" €", "paj": "oui", _rowVariant: "" })
            this.resultats.push({ "Element": "Indemnités Entretien", "Valeur": parseFloat(this.declaration.indemnites_entretien).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Indemnités Entretien Partiel", "Valeur": parseFloat(this.declaration.indemnites_entretien_partiel).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Sous-Total Indemnites Entretien", "Valeur": parseFloat(this.declaration.indemnites_entretien + this.declaration.indemnites_entretien_partiel).toFixed(2)+" €", "paj": "oui", _rowVariant: "" })
            this.resultats.push({ "Element": "Indemnités Repas", "Valeur": parseFloat(this.declaration.total_repas).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Indemnités Gouter", "Valeur": parseFloat(this.declaration.total_gouter).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Sous Total Gouter+Repas", "Valeur": parseFloat(this.declaration.total_gouter + this.declaration.total_repas).toFixed(2)+" €", "paj": "oui", _rowVariant: "" })
            this.resultats.push({ "Element": "Total Indemnités", "Valeur": parseFloat(this.declaration.total_des_Indemnites).toFixed(2)+" €", "paj": "", _rowVariant: "" })
            this.resultats.push({ "Element": "Salaire Net à verser", "Valeur": parseFloat(this.declaration.salaire_mensuel).toFixed(2)+" €", "paj": "oui", _rowVariant: "" })
        },

        genererPajKit: function () {
            this.pajKit = this.resultats.filter(i => i.paj === "oui");
            console.log(this.pajKit);
        },
        loadFromLocalStorage: function () {
            console.log(localStorage);
            if (localStorage.enfant) {
                this.enfant = localStorage.enfant;
            }
            if (localStorage.heures) {
                this.mensualisation.heures.value = localStorage.heures;
            }
            if (localStorage.jours) {
                this.mensualisation.jours.value = localStorage.jours;
            }
            if (localStorage.salaire) {
                this.mensualisation.salaire.value = localStorage.salaire;
            }
            if (localStorage.tarifEntretien) {
                this.tarifs.entretien.value = localStorage.tarifEntretien;
            }
            if (localStorage.tarifEntretienPartiel) {
                this.tarifs.entretienPartiel.value = localStorage.tarifEntretienPartiel;
            }
            if (localStorage.tarifGouter) {
                this.tarifs.gouter.value = localStorage.tarifGouter;
            }
            if (localStorage.tarifRepas) {
                this.tarifs.repas.value = localStorage.tarifRepas;
            }
            if (localStorage.tarifHoraire) {
                this.tarifs.horaire.value = localStorage.tarifHoraire;
            }
        },
        mainProgram: function () {
            this.calculate()
            this.result = true
            this.generateResult()
            this.genererPajKit()
        }
    },
    mounted: function () {
        console.log("mounted");
        // this.menu = "form"
        this.loadFromLocalStorage();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
