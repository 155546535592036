<template>
  <div id="app"> 
    <!--<img alt="Vue logo" src="./assets/logo.png">--> 
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <navBar title="pajoutils.me"></navBar>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import nouNou from './components/nounou.vue'
import navBar from './components/navbar.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    //nouNou,
    navBar
  }
}
</script>

<style>
#app {
  font-family:  Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background: url("/public/bebe.jpeg") no-repeat; 
  background-size:cover; 
  
}

</style>
