<template>
    <b-container>
        <template v-if="intro">
          <div class="fixed-top">
            <b-container>
                <b-row class="vh-100 text-center" align-v="center">
                  <b-col>
                   <b-jumbotron header="PAJE OUTILS" lead="Calcul de salaire mensualisé pour garde d'enfant">
                      <hr>
                      <p>Que souhaitez-vous faire ?</p>
                   
                      <b-button size="lg" variant="outline-success" v-on:click="navigate('calculSalaire')">Je calcule le salaire</b-button>&nbsp;
                      <b-button size="lg" variant="outline-primary" v-on:click="navigate('docs')">Je cherche des informations</b-button>
                    </b-jumbotron>
                  </b-col>
                </b-row>
            </b-container>
          </div>
        </template>
        <template v-else>
        <div class="fixed-top">
          <b-navbar toggleable="lg" type="dark" variant="dark">
          <b-navbar-brand href="#">{{title}}</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item href="#" v-on:click="navigate('calculSalaire')">Calcul Salaire Mensualisé</b-nav-item>
              <b-nav-item href="#" v-on:click="navigate('docs')">Docs Pajemploi</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        </div>
        </template>
        <template v-if="menu === 'calculSalaire'">
          <nouNouCalc></nouNouCalc>
        </template>
        <template v-if="menu === 'docs'">
          <nouNouDocs></nouNouDocs>
        </template>
    </b-container>
</template>

<script>

import nouNouCalc from './nounouCalc.vue'
import nouNouDocs from './nounouDocs.vue'
export default {
  name: 'navBar',
  components: {
    nouNouCalc,
    nouNouDocs
  },
  props: {
    title: String
  },
  data() {
      return {
        menu:"",
        intro:true

      }
  },
  methods: {
    navigate: function(value) {
      this.menu = value
      this.intro = false
    }
  }
}
</script>

<style>

</style>